import { HeartbeatAreaChartErrorPrompt } from './HeartbeatAreaChartErrorPrompt';
import React, { useEffect, useState, FC } from 'react';
import {
  Area,
  AreaChart,
  CartesianGrid,
  ReferenceLine,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';
import { CustomTooltip } from './AreaChartTooltip';
import { useDoubleClick, useLongLoading } from '@tapestry/shared/hooks';
import {
  calculateInterval,
  checkifItIsFirstMonthlyTick,
  getActualRepresentedDataTicks,
  getTodayPayload,
  getWidthByMaxNumber,
  IHeartbeatAreaChartProps,
  MONTH_LENGTH,
  pickHighestDataKey,
} from './HeartbeatAreaChartUtils';
import {
  dateTime,
  getCurrentAppInfo,
  parseToCurrencyString,
} from '@tapestry/shared/utils';
import { HeartbeatAreaChartLoadingState } from './HeartbeatAreaChartLoadingState';
import { CustomXAxisTick } from './CustomXAxisTick';
import { API_DATETIME_DAY_FORMAT, normaliseDatetime } from '../../utils';
import { IParsedDataValue } from '@tapestry/types';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { useAppMediaQuery } from '@tapestry/weave';
import { CategoricalChartState } from 'recharts/types/chart/types';

const today = dateTime.now().format();
const MARGINS = {
  top: 0,
  right: 0,
  left: 0,
  bottom: 0,
};

let appColor = getCurrentAppInfo().appInfo.color;

/**
 * The main area chart of the heartbeat applet
 */
export const HeartbeatAreaChart: FC<IHeartbeatAreaChartProps> = ({
  chartData,
  isLoadingChartData,
  chartDataError,
  label,
  unit,
  comparisonUnit,
  isIntraDay,
  isHourly,
  setUrlQueryState,
  comparisonTooltipLabel,
  currentShopTimezone,
  refetchChart,
  chartCaption,
  isLoadingComparisonData,
}) => {
  /**
   * Hooks
   */
  const { loadingMessage } = useLongLoading({
    isLoading: isLoadingChartData,
    mode: 'interval',
  });

  const appQueries = useAppMediaQuery();
  const { isPhone } = appQueries;
  const [todayPayload, setTodayPayload] = useState<IParsedDataValue[] | null>(
    null
  );
  const { mainChartFiveMinutesDrilldown: hasFiveMinsDrillDownPermission } =
    useFlags();

  /**
   * Const
   */
  const dataLength = chartData?.length || 0;
  const interval = calculateInterval(appQueries, dataLength);
  const dataKey = pickHighestDataKey(chartData);
  const chartedData = getActualRepresentedDataTicks(chartData, interval);

  // label as "2022-09-12"
  const setChartToDailyView = ({ activeLabel }: CategoricalChartState) => {
    if (isIntraDay || !activeLabel) return;

    const isoDate = dateTime
      .parse(activeLabel, API_DATETIME_DAY_FORMAT)
      .toISOString();

    const newParams = normaliseDatetime(
      { start: isoDate, end: isoDate },
      'day',
      currentShopTimezone
    );

    setUrlQueryState({
      startDate: newParams.start,
      endDate: newParams.end,
    });
  };

  const setChartTo5MinutesView = (props: CategoricalChartState) => {
    // activeLabel as 2022-06-23 15:00:00
    const { activeLabel } = props;

    const startOfHour = dateTime
      .parseInTimezone(activeLabel, currentShopTimezone, 'YYYY-MM-DD HH:mm:ss')
      .startOf('hour')
      .format();
    const endOfHour = dateTime
      .parseInTimezone(activeLabel, currentShopTimezone, 'YYYY-MM-DD HH:mm:ss')
      .endOf('hour')
      .format();

    setUrlQueryState({
      startDate: startOfHour,
      endDate: endOfHour,
    });
  };

  const handleChartClick = (props: CategoricalChartState) => {
    if (isHourly) return;

    if (isIntraDay && hasFiveMinsDrillDownPermission) {
      setChartTo5MinutesView(props);
    } else {
      setChartToDailyView(props);
    }
  };

  const handleDoubleClick = useDoubleClick(handleChartClick);

  /**
   * if on mobile, on page load it defaults the tooltip to today's date
   */
  useEffect(() => {
    if (!chartData) return;
    const todayData = getTodayPayload(today, chartData);
    setTodayPayload(todayData);
  }, [chartData]);

  return (
    <div className="w-full">
      <figure className="xxl:h-72 relative h-64 w-full">
        {chartDataError && !isLoadingChartData ? (
          <HeartbeatAreaChartErrorPrompt refetchChart={refetchChart} />
        ) : null}

        {isLoadingChartData ? (
          <HeartbeatAreaChartLoadingState loadingMessage={loadingMessage} />
        ) : (
          <ResponsiveContainer>
            <AreaChart
              data={chartData}
              margin={MARGINS}
              style={{
                cursor: isHourly ? 'auto' : 'pointer',
              }}
              onMouseEnter={() => setTodayPayload(null)}
              onClick={handleDoubleClick}
            >
              <CartesianGrid strokeDasharray="3 3" />

              <XAxis
                axisLine={false}
                allowDataOverflow={false}
                interval={interval}
                padding={{ left: comparisonUnit ? 0 : 12 }}
                height={dataLength > MONTH_LENGTH || isIntraDay ? 67 : 55}
                tickLine={false}
                dataKey="name"
                tick={
                  <CustomXAxisTick
                    isMonthlyViewOnMobile={isPhone}
                    dataLength={dataLength}
                    checkForFirstMonthlyTick={(tick) =>
                      checkifItIsFirstMonthlyTick(chartedData, tick)
                    }
                    isIntraDay={isIntraDay}
                  />
                }
              />

              <YAxis
                yAxisId="right"
                dataKey={dataKey}
                type="number"
                domain={[0, (dataMax: number) => dataMax * 1.4]}
                tickFormatter={(value) =>
                  parseToCurrencyString(
                    value,
                    unit?.symbol || '',
                    unit?.position || 'left',
                    {
                      noDecimals: true,
                      thousandsInKs: true,
                    }
                  )
                }
                tick={{ fontSize: '10px' }}
                allowDecimals={false}
                axisLine={false}
                width={getWidthByMaxNumber(
                  chartData.map((entry) => entry.value ?? 0)
                )}
                orientation="right"
                tickLine={false}
                tickMargin={0}
              />

              {comparisonUnit && (
                <YAxis
                  yAxisId="left"
                  dataKey="comparison"
                  type="number"
                  domain={[0, (dataMax: number) => dataMax * 1.4]}
                  tickFormatter={(value) =>
                    parseToCurrencyString(
                      value,
                      comparisonUnit?.symbol || '',
                      comparisonUnit?.position || 'left',
                      {
                        noDecimals: true,
                        thousandsInKs: true,
                      }
                    )
                  }
                  tick={{ fontSize: '10px' }}
                  allowDecimals={false}
                  axisLine={false}
                  width={
                    getWidthByMaxNumber(
                      chartData.map((entry) => entry.comparison ?? 0)
                    ) + 2
                  }
                  tickLine={false}
                  tickMargin={0}
                />
              )}

              <Area
                dataKey="comparison"
                yAxisId={comparisonUnit ? 'left' : 'right'}
                strokeWidth={2}
                strokeDasharray="10 5"
                strokeDashoffset={20}
                stroke={isLoadingComparisonData ? 'rgba(0,0,0,0)' : '#888888'}
                fill={appColor}
                fillOpacity={0.4}
                animationBegin={1800}
                animationEasing="ease-out"
                animationDuration={380}
              />

              <Area
                dataKey="value"
                yAxisId={'right'}
                strokeWidth={3}
                stroke={appColor}
                fill={appColor}
                dot={dataLength === 1}
              />

              <Tooltip
                wrapperStyle={isPhone ? { visibility: 'visible' } : {}}
                cursor={{ stroke: 'black' }}
                content={
                  <CustomTooltip
                    title={label}
                    unit={unit}
                    comparisonUnit={comparisonUnit}
                    todaysPayload={todayPayload}
                    comparisonTooltipLabel={comparisonTooltipLabel}
                    viewScope={{ isHourly, isIntraDay }}
                  />
                }
              />

              <ReferenceLine
                x={today}
                stroke="rgba(0,0,0,0.4)"
                yAxisId="right"
              />
            </AreaChart>
          </ResponsiveContainer>
        )}

        <figcaption
          data-testid="primary-chart-caption"
          className="text-gray-text sr-only text-xs"
        >
          {chartCaption}
        </figcaption>
      </figure>
    </div>
  );
};

export default HeartbeatAreaChart;
