import React, { useEffect, useState, FC } from 'react';
import {
  AnalyseButton,
  Button,
  Card,
  MainHeading,
  Panel,
  ResponsiveDropdownMenu,
  useAppMediaQuery,
} from '@tapestry/weave';
import Skeleton from 'react-loading-skeleton';
import {
  ExclamationCircleIcon,
  InfoCircleIcon,
  SearchIcon,
  SortDownIcon,
} from '@tapestry/shared/icons';
import {
  HANK_UNI_URLS,
  ROUTE_PATHS,
  SUPPORT_EMAIL,
} from '@tapestry/shared/constants';
import { useMediaQuery } from 'react-responsive';
import { ErrorBoundary, useToast, useUIContext } from '@tapestry/shared/client';
import {
  useLongLoading,
  useModal,
  useSortMenu,
  useURIReturnPath,
} from '@tapestry/shared/hooks';
import {
  capitaliseString,
  parseToCurrencyString,
  safeJSONParse,
} from '@tapestry/shared/utils';
import isEmpty from 'lodash/isEmpty';
import { IMetric, Visualization } from '@tapestry/types';
import { HeartbeatVisualizationQueriesBaseVariables } from '../../constants';
import {
  IDataValueId,
  useGetHeartbeatBarChartData,
  useGetHeartbeatBarChartPaginatedData,
} from '@tapestry/shared/graphql';
import {
  calculatePercentage,
  HorizontalBarLoadingState,
} from './HeartbeatHorizontalBarUtils';
import { VisualizationSearchModal } from '@tapestry/shared/visualisation';
import { ChartErrorView } from '../ChartErrorView/ChartErrorView';
import { useFlags } from 'launchdarkly-react-client-sdk';
import Link from 'next/link';
import { useDebounce } from 'use-debounce';
import { captureException } from '@sentry/nextjs';

const NoResultsWarning: FC = () => (
  <Card bgColor="bg-gray-100">
    <div className="flex">
      <ExclamationCircleIcon className="mt-1 h-6 w-auto flex-shrink-0" />
      <p className="ml-4 text-left">
        We're sorry, but we couldn't retrieve your data at this time. <br />
        This could be because there's no data available, or there might be an
        issue with our servers. <br />
        If you believe this to be an error, contact{' '}
        <a href={`mailto:${SUPPORT_EMAIL}`} className="text-primary underline">
          support
        </a>
        .
      </p>
    </div>
  </Card>
);

const getMaxValue = (data: IDataValueId[] | undefined) => {
  if (!data) return 0;
  const maxValue = Math.max(...data.map((item) => item.value || 0));

  return maxValue;
};

interface IHorizontalChartQueryVariables
  extends HeartbeatVisualizationQueriesBaseVariables {
  scopeType: string;
  threadType: string;
}

export interface IHeartbeatHorizontalBarProps {
  title: string;
  queryVariables: IHorizontalChartQueryVariables;
  unit: IMetric['unit'];
  isLoading?: boolean;
  stringifyQueryParams: (id: string) => string;
  readOnly?: boolean;
  visualisation: Visualization;
}

const SEARCH_QUERY_DEBOUNCE = 250;
const SEARCH_RESULT_LIMIT = 20;
const INITIAL_PAGE_NO = 1;

const HeartbeatHorizontalBar: FC<IHeartbeatHorizontalBarProps> = ({
  title,
  queryVariables,
  unit,
  stringifyQueryParams,
  readOnly,
  visualisation,
}) => {
  /**
   * Hooks
   */
  const { addToast } = useToast();
  const [page, setPage] = useState(1);
  const {
    items,
    state: { sortBy },
  } = useSortMenu();

  const [search, setSearch] = useState('');
  const [debouncedSearch] = useDebounce(search, SEARCH_QUERY_DEBOUNCE);
  const returnTo = useURIReturnPath();

  // UI
  const searchModalState = useModal();
  const [
    {
      threadTypeThemeColors: {
        backgroundColor,
        backgroundColorHover,
        textColor,
        textColorHover,
      },
    },
  ] = useUIContext();

  const { threadSummary: hasThreadSummaryFlag } = useFlags();

  const isDesktop = useMediaQuery({ minWidth: 1200 });
  const { isPhone, isSmallComputerAndUp } = useAppMediaQuery();

  const filters = safeJSONParse(queryVariables?.filters);
  const hasId = filters?.groupId || filters?.shopId;
  const baseQueryVariables = React.useMemo(
    () => ({
      ...queryVariables,
      search: '',
      limit: 5,
      page: INITIAL_PAGE_NO,
      sortBy,
    }),
    [queryVariables, sortBy]
  );

  const { data: chartData, loading: isChartDataLoading } =
    useGetHeartbeatBarChartData({
      skip: !hasId,
      variables: baseQueryVariables,
      onError: () => {
        addToast({
          type: 'error',
          content: `An error has occured whilst retrieving the data for ${queryVariables.scopeType}. `,
        });
      },
      onCompleted: (data) => {
        const initialChartDataResults = data?.heartbeatChartBarV3?.data;

        if (isEmpty(initialChartDataResults)) {
          captureException('No data found for this query', {
            level: 'info',
            tags: { visualisation },
          });
        }
      },
    });

  const {
    data: modalData,
    loading: isModalDataLoading,
    refetch: refetchModalData,
  } = useGetHeartbeatBarChartPaginatedData({
    skip: !hasId || !chartData,
    variables: {
      ...baseQueryVariables,
      limit: SEARCH_RESULT_LIMIT,
      sortBy,
    },
    onError: () => {
      addToast({
        type: 'error',
        content: `An error has occured whilst retrieving the data for ${queryVariables.scopeType}. `,
      });
    },
  });

  const { loadingMessage } = useLongLoading({
    mode: 'interval',
    isLoading: isChartDataLoading,
  });

  const initialChartDataResults = chartData?.heartbeatChartBarV3?.data;
  const maxValue = getMaxValue(initialChartDataResults);
  const dataList = modalData?.heartbeatChartBarV3?.data || [];
  const pagination = modalData?.heartbeatChartBarV3?.pagination;

  /**
   * Const
   */

  const handleStringifyQueryParamsWithNew = (id: string) => {
    if (stringifyQueryParams) {
      return stringifyQueryParams(id);
    }

    return '';
  };

  const handleSortbyChange = (sortBy: string) => {
    setPage(INITIAL_PAGE_NO);
    refetchModalData({ sortBy, page: INITIAL_PAGE_NO });
  };

  const handleLinkWithPermissions = (id) => {
    return hasThreadSummaryFlag
      ? ROUTE_PATHS.build.threadSummary(id, {
          returnTo,
        })
      : ROUTE_PATHS.build.heartbeat({
          threadType: queryVariables.threadType,
          qp: handleStringifyQueryParamsWithNew(id || ''),
        });
  };

  const notifyOfReadOnlyState = () => {
    alert('Coming Soon...');
  };

  const handlePreviousePage = (page?: number) => {
    setPage((currentPage) => {
      const newPage = page || currentPage - 1;
      const _page = newPage > INITIAL_PAGE_NO ? newPage : INITIAL_PAGE_NO;

      refetchModalData({ page: _page });

      return _page;
    });
  };

  const handleNextPage = (page?: number) => {
    setPage((currentPage) => {
      const newPage = page || currentPage + 1;

      refetchModalData({ page: newPage });

      return newPage;
    });
  };

  // -------------------------------------------
  // useEffect
  // -------------------------------------------
  useEffect(() => {
    if (!searchModalState.isOpen) {
      return;
    }

    refetchModalData({
      ...baseQueryVariables,
      search: debouncedSearch,
      limit: SEARCH_RESULT_LIMIT,
    });
  }, [
    baseQueryVariables,
    debouncedSearch,
    refetchModalData,
    searchModalState.isOpen,
  ]);

  return (
    <ErrorBoundary errorView={<ChartErrorView />}>
      <Panel
        noVertSpacing={isSmallComputerAndUp}
        noHorizontalPadding={isDesktop}
      >
        <header className="mb-4 flex flex-row items-center justify-between">
          <div>
            <MainHeading>
              {title || <Skeleton />}

              <span className="ml-2 hidden sm:inline-block">
                <a
                  href={HANK_UNI_URLS.heartbeat[queryVariables.threadType]}
                  target="_blank"
                  rel="noopener noreferrer"
                  title={`Learn more about ${title}`}
                >
                  <span className="sr-only">Learn more about {title}</span>
                  <InfoCircleIcon fillColor="#666666" className="h-4 w-auto" />
                </a>
              </span>
            </MainHeading>
          </div>

          <div className="ml-4 flex items-center justify-end space-x-3">
            <button
              title={`Search ${title}`}
              className="h-8 rounded-full bg-gray-200 p-2 hover:bg-gray-300 focus:bg-gray-300 focus:outline-none sm:h-6 sm:p-1"
              onClick={searchModalState.open}
              onKeyDown={({ key }) => {
                if (key === 'Enter') {
                  searchModalState.open();
                }
              }}
            >
              <SearchIcon />
            </button>

            <ResponsiveDropdownMenu>
              <ResponsiveDropdownMenu.Button
                title={`Sort ${title}`}
                className="h-8 rounded-full bg-gray-200 p-2 hover:bg-gray-300 focus:bg-gray-300 focus:outline-none sm:h-6 sm:p-1"
              >
                <span className="sr-only">Sort {title}</span>
                <SortDownIcon />
              </ResponsiveDropdownMenu.Button>

              <ResponsiveDropdownMenu.Menu>
                {items.map((item) => (
                  <ResponsiveDropdownMenu.StyledMenuItem
                    key={item.label}
                    label={item.label}
                    onClick={item.onclick}
                    isActive={item.id === sortBy}
                  />
                ))}
              </ResponsiveDropdownMenu.Menu>
            </ResponsiveDropdownMenu>
          </div>
        </header>

        {isEmpty(initialChartDataResults) && !isChartDataLoading ? (
          <NoResultsWarning />
        ) : null}

        {isEmpty(initialChartDataResults) && isChartDataLoading ? (
          <ul className="min-h-[309px] space-y-4">
            <li className="relative block">
              <HorizontalBarLoadingState />
              {loadingMessage ? (
                <p className="absolute left-1/2 top-1/2 z-10 -translate-x-1/2 -translate-y-1/2 transform text-center font-medium tracking-wide text-gray-700">
                  {loadingMessage}
                </p>
              ) : null}
            </li>
          </ul>
        ) : null}

        {!isEmpty(initialChartDataResults) && !isChartDataLoading ? (
          <>
            <ul className="min-h-[309px] space-y-4">
              {initialChartDataResults?.map(({ id, name, value }) => {
                const valPercentage =
                  calculatePercentage(value || 0, maxValue) * 100;

                return (
                  <li
                    key={`${id}${name}`}
                    className="flex w-full items-end justify-between gap-2"
                  >
                    <Link
                      href={handleLinkWithPermissions(id)}
                      className={`group flex-grow cursor-pointer focus:outline-none ${
                        readOnly ? 'cursor-auto' : ''
                      }`}
                      onClick={(e) => {
                        if (!hasThreadSummaryFlag && readOnly) {
                          e.preventDefault();
                          notifyOfReadOnlyState();
                        }
                      }}
                      onKeyDown={(e) => {
                        if (e.key === 'Enter') {
                          if (!hasThreadSummaryFlag && readOnly) {
                            e.preventDefault();
                            notifyOfReadOnlyState();
                          }
                        }
                      }}
                    >
                      <h3
                        title={name || ''}
                        className={`mb-1 truncate text-sm transition-colors duration-150 ease-out group-focus:outline-none ${
                          textColor ?? 'text-gray-text'
                        } group-hover:${textColorHover} group-focus:${textColorHover}`}
                      >
                        {capitaliseString(name)}
                      </h3>
                      {/* the bars */}
                      <div
                        className="flex flex-row flex-nowrap items-center"
                        style={{
                          width: `${valPercentage}%`,
                          minWidth: '40%',
                        }}
                      >
                        <div
                          className={`h-6 rounded-md transition-colors duration-150 ease-out group-focus:outline-none ${
                            valPercentage < 1 ? 'w-1' : 'w-full'
                          } ${
                            backgroundColor ?? 'bg-primary'
                          } group-focus:${backgroundColorHover} group-hover:${backgroundColorHover}`}
                        />
                        <span className="ml-2 flex-shrink-0 text-base font-bold text-black">
                          {parseToCurrencyString(
                            value || 0,
                            unit?.symbol,
                            unit?.position
                          )}
                        </span>
                      </div>
                    </Link>
                    {hasThreadSummaryFlag ? (
                      <Link
                        title={`View ${name} in Heartbeat`}
                        href={ROUTE_PATHS.build.heartbeat({
                          threadType: queryVariables.threadType,
                          qp: handleStringifyQueryParamsWithNew(id || ''),
                        })}
                      >
                        <AnalyseButton variant={isPhone ? 'icon' : 'compact'} />
                        <span className="sr-only">
                          View {name} in heartbeat
                        </span>
                      </Link>
                    ) : null}
                  </li>
                );
              })}
            </ul>

            <div className="mt-8">
              <Button
                spacing="small"
                loading={isChartDataLoading}
                onClick={searchModalState.open}
              >
                See All
              </Button>
            </div>
          </>
        ) : null}

        {searchModalState.isOpen && pagination && (
          <VisualizationSearchModal
            title={title}
            defaultSortBy={sortBy}
            data={dataList}
            isLoading={isModalDataLoading}
            unit={unit}
            onInputChange={(value) => {
              setPage(1);
              setSearch(value);
            }}
            onSortbyChange={handleSortbyChange}
            modalState={searchModalState}
            onLinkClicked={(item) => handleLinkWithPermissions(item?.id)}
            readOnly={readOnly}
            withPagination={{
              showBoundaryPages: true,
              boundaryPagesAmount: 2,
              showFirstAndLastPage: true,
              lastPage: pagination.totalPages,
              currentPage: page,
              isLastPage: page === pagination.totalPages,
              onPrev: handlePreviousePage,
              onPrevWithPageNumber: handlePreviousePage,
              onNext: handleNextPage,
              onNextWithPageNumber: handleNextPage,
            }}
          />
        )}
      </Panel>
    </ErrorBoundary>
  );
};

export { HeartbeatHorizontalBar };
export default HeartbeatHorizontalBar;
