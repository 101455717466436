import React, { FC } from 'react';
import { PrimaryChartDataDate } from '@tapestry/types';
import {
  getMonthLabelShowCondition,
  handleTickFormatting,
  MONTH_LENGTH,
} from './CutomXAxisTickUtils';
import { Payload } from 'recharts/types/component/DefaultTooltipContent';
import { useAppMediaQuery } from '@tapestry/weave';

interface ICustomXAxisTick {
  x?: number;
  y?: number;
  payload?: Payload<string, string>;
  isMonthlyViewOnMobile: boolean;
  dataLength: number;
  checkForFirstMonthlyTick: (
    tick: Payload<string, string> | undefined
  ) => boolean;
  isIntraDay: boolean;
}

/**
 * Displays the tick UI
 */
export const CustomXAxisTick: FC<ICustomXAxisTick> = ({
  x,
  y,
  payload,
  isMonthlyViewOnMobile,
  dataLength,
  checkForFirstMonthlyTick,
  isIntraDay,
}) => {
  const {
    dayOfMonth,
    weekdayName,
    isToday,
    monthName,
    hour,
    isCurrentHour,
    meridiem,
    minutes,
  } = handleTickFormatting(payload?.value as PrimaryChartDataDate, isIntraDay);
  const isFirstmonthlyTick = checkForFirstMonthlyTick(payload);
  const isLongerThanAMonth = dataLength > MONTH_LENGTH;
  const shouldShowMonthName = getMonthLabelShowCondition(
    dataLength,
    isFirstmonthlyTick
  );
  const { isPhone, isTablet } = useAppMediaQuery();
  const isTabletOrSmaller = isPhone || isTablet;

  return (
    <g transform={`translate(${x},${y})`}>
      {isIntraDay && (
        <>
          <text
            x={0}
            y={0}
            // Y axis displacement in pixels
            dy={16}
            textAnchor="middle"
            fill={isCurrentHour ? '#000' : '#666'}
            fontSize="12px"
          >
            {hour}
          </text>

          {isTabletOrSmaller && (
            <text
              x={0}
              y={0}
              // Y axis displacement in pixels
              dy={32}
              textAnchor="middle"
              fill={isCurrentHour ? '#000' : '#666'}
              fontSize="12px"
            >
              {minutes}
            </text>
          )}

          <text
            x={0}
            y={0}
            // Y axis displacement in pixels
            dy={isTabletOrSmaller ? 48 : 32}
            textAnchor="middle"
            fill={isCurrentHour ? '#000' : '#666'}
            fontSize="12px"
          >
            {meridiem}
          </text>
        </>
      )}

      {shouldShowMonthName && (
        <text
          x={0}
          y={0}
          // Y axis displacement in pixels
          dy={16}
          textAnchor="middle"
          fill={isToday ? '#000' : '#666'}
          fontSize="12px"
        >
          {monthName}
        </text>
      )}

      {!isIntraDay && (
        <>
          <text
            x={0}
            y={0}
            // Y axis displacement in pixels
            dy={isLongerThanAMonth ? 32 : 16}
            textAnchor="middle"
            fill={isToday ? '#000' : '#666'}
            fontSize="12px"
          >
            {dayOfMonth}
          </text>

          <text
            x={0}
            y={0}
            // Y axis displacement in pixels
            dy={isLongerThanAMonth ? 48 : 32}
            textAnchor="middle"
            fill={isToday ? '#000' : '#666'}
            fontVariant="all-small-caps"
            fontSize="10px"
          >
            {weekdayName}
          </text>
        </>
      )}

      {(isToday && !isIntraDay) ||
        (isIntraDay && isCurrentHour && (
          <line
            x1={isMonthlyViewOnMobile ? -8 : -14}
            x2={isMonthlyViewOnMobile ? 8 : 14}
            y1={isLongerThanAMonth ? 56 : 44}
            y2={isLongerThanAMonth ? 56 : 44}
            strokeWidth="4"
            stroke="black"
            strokeLinecap="round"
          />
        ))}
    </g>
  );
};
